
@import "../../../../assets/stylesheets/variables";

button {
  font-size: 1.25rem;
}
.fa-toggle-off {
  color: #bbb;
}

.search--description .toggle-test-data button.btn {
  color: $tito-light-blue;
  font-size: 1em;
}
