
@import "../../../../assets/stylesheets/variables";
span.locales-switcher {
  position: absolute;
  right: 0;
  top: -1.7rem;
  text-align: right;
  max-width: 65rem;
  @media (min-width: 768px) {
  }
  a.locale_switch {
    border: 1px solid #d8d8d8;
    color: #fff;
    background: #d8d8d8;
    display: inline-block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 1.5rem;
    padding: 0 0.75rem;
    position: relative;
    top: -5px;
    text-transform: uppercase;
    z-index: 1;
  }
  a.active {
    background: #fff;
    border-bottom-color: #fff;
    color: $tito-light-blue;
  }
}

div.switch-mode {
  font-size: 1.2rem;
  position: absolute;
  right: 0;
}
div.hint {
  font-size: 1.2rem;
  padding: 2px 0 0 0;
  text-align: right;
}
