
@import "../../../../assets/stylesheets/variables";

table.table {
  tr:first-child {
    th,
    td {
      border-top: none;
    }
  }
  tr:last-child {
    th,
    td {
      border-top: 3px double $divider;
    }
  }
}
