
@import "../../../../assets/stylesheets/variables";

.dream-picker {
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: center;

  .calendar-wrapper {
    position: relative;
    .calendar {
      background-color: #fff;
      padding: 1rem;
      position: absolute;
      top: 6px;
      left: 1rem;
      border: 1px solid rgba(0, 0, 0, 0.15);
      z-index: 1200;

      &::before {
        left: 1.9rem;
        top: -7px;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-bottom: 7px solid $divider;
        position: absolute;
        display: inline-block;
        content: "";
      }
      &::after {
        left: 2rem;
        top: -6px;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
        border-left: 6px solid transparent;
        position: absolute;
        display: inline-block;
        content: "";
      }
    }
  }
}
.years {
  display: flex;
  justify-content: center;
  overflow: auto;
  width: 100%;
  .year {
    font-size: 1.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  > a {
    flex: 1;
    line-height: 1;
    &:last-of-type {
      text-align: right;
    }
  }
}
.active {
  font-weight: bold;
}
.choose-months {
  .choose-month {
    display: block;
    padding-left: 2rem;
  }
}
.months {
  display: flex;
  overflow: auto;
  width: 100%;
  h5 {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 3rem;
    text-transform: none;
  }
  .month {
    display: flex;
    flex-direction: column;
    text-align: center;
    &--header {
      display: flex;
      font-size: 1.5rem;
    }
    &--title {
      flex: 1;
    }
    a.choose-month {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3rem;
      width: 100%;
    }
    .days {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 21rem;
      .day-header {
        font-size: 1.2rem;
        font-weight: 700;
      }
      .day {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3rem;
        a {
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          min-height: 3rem;
        }
        a.today {
          background: rgba(0, 0, 0, 0.1);
        }
        a.window {
          color: #000;
          opacity: 0.25;
        }
        a.start {
          background-color: $tito-orange;
          border: 1px solid $tito-orange;
          color: white;
        }
        a.end {
          background-color: red;
        }
      }
    }
  }
}
