
@import "../../../../assets/stylesheets/variables";
.sorting {
  background: #fff;
  border: 1px solid $divider;
  max-height: 42vh;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 49px;
  z-index: 1;
}
