<template>
  <div class="card card--edit">
    <div class="enable-disable-header">
      <div class="description">
        <h2 class="heading-4">{{ title }}</h2>
        <p
          class="body-text-small text-muted"
          v-html="description"
          v-if="description"
        ></p>
        <slot name="description"></slot>
      </div>
      <div class="card--header--action" v-if="!alwaysOn">
        <a
          href="#"
          class="btn btn-secondary"
          @click.stop.prevent="show = true"
          v-show="!show"
        >
          <span class="d-none d-md-inline">{{ on_label_value }}</span>
          <span class="d-sm-block d-md-none">{{ on_label_value }}</span>
        </a>
        <a
          href="#"
          v-cloak
          class="btn btn-link"
          @click.stop.prevent="show = false"
          v-show="show"
        >
          <span class="d-none d-md-inline">{{ off_label_value }}</span>
          <span class="d-sm-block d-md-none">{{ off_label_value }}</span>
        </a>
      </div>
    </div>
    <div class="viewer--content" v-if="show"><slot name="enabled"></slot></div>
    <slot name="disabled" v-if="!show"></slot>
  </div>
</template>

<script>
export default {
  name: "enable-disable",
  props: {
    title: String,
    description: String,
    alwaysOn: {
      type: Boolean,
      default: false,
    },
    openIf: [Boolean, String, Number],
    onLabel: String,
    offLabel: String,
    value: [Boolean, String, Number],
  },

  data() {
    return { show: this.openIf || this.value };
  },
  watch: {
    show() {
      if (this.show) {
        this.$emit("input", true);
        this.$emit("on");
      } else {
        this.$emit("input", false);
        this.$emit("off");
      }
    },
  },
  computed: {
    on_label_value() {
      return this.onLabel || "Add";
    },
    off_label_value() {
      return this.offLabel || "Remove";
    },
  },
};
</script>

<style scoped>
.btn {
  min-width: 15rem;
}
</style>
