
@import "../../../../assets/stylesheets/variables";

a.attachment--toggle-all {
  color: $text-dark;
  display: flex;
  font-size: 2rem;
  gap: 1rem;
}

.attachments--actions {
  align-self: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
  position: relative;
  &.no-search {
    margin-bottom: 2.5rem;
  }
}
.selection-description {
  bottom: -16px;
  position: absolute;
}
code {
  color: $text-dark;
  font-size: 1.2rem;
}

.toggleHint {
  color: $text-dark;
  font-size: 1.5rem;
}

.attachment--selection-message {
  border-bottom: 1px solid $divider;
  display: flex;
  justify-content: space-between;
}

.page-link {
  background-color: transparent;
  border: none;
}

.no-page-link {
  display: block;
  line-height: 1.25;
  opacity: 0.5;
  padding: 0.5rem 0.75rem;
}

.attachments--header {
  background-color: #f7faff;
  border-bottom: 1px solid $divider;
  border-top: 1px solid $divider;
  display: flex;
  flex-direction: column;
  margin: -2rem -2rem 0;
  padding: 2rem 2rem 0;
}
.attachments--actions {
  flex: 1;
}
.toggle-and-search {
  // display: flex;
  padding: 0 0 0 1.5rem;
}

.pagination-control {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem 0.5rem;
}

@media (min-width: 768px) {
  .attachments--actions {
    margin-bottom: 1rem;
  }
  .toggle-and-search {
    display: flex;
  }
  .attachments--header {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 1rem;
  }
}
