<template>
  <div class="design-guide">
    <div class="design-guide--container" role="main">
      <div class="design-guide--content">
        <div class="design-guide--header">
          <img src="~/images/design_guide/heart.png" class="img-fluid" alt="" />
        </div>
        <h1 class="text-center">Tito Design Guide</h1>
        <hr />
        <p>
          Hello! You’re now amongst friends, and we hope your life as a designer
          is about to get&nbsp;easier.
        </p>

        <div class="design-guide--section">
          <h2 class="design-guide--section-heading">Colours</h2>
          <div class="design-guide--swatches">
            <div class="design-guide--swatch">
              <div class="swatch-colour swatch-colour--tito-light-blue">
              </div>
              <span class="swatch-name">
                $tito-light-blue
              </span>
            </div>

            <div class="design-guide--swatch">
              <div class="swatch-colour swatch-colour--tito-mid-blue">
              </div>
              <span class="swatch-name">
                $tito-mid-blue
              </span>
            </div>

            <div class="design-guide--swatch">
              <div class="swatch-colour swatch-colour--tito-red">

              </div>
              <span class="swatch-name">
                $tito-red
              </span>
            </div>

            <div class="design-guide--swatch">
              <div class="swatch-colour swatch-colour--tito-orange">

              </div>
              <span class="swatch-name">
                $tito-orange
              </span>
            </div>

            <div class="design-guide--swatch">
              <div class="swatch-colour swatch-colour--tito-green">

              </div>
              <span class="swatch-name">
                $tito-green
              </span>
            </div>

            <div class="design-guide--swatch">
              <div class="swatch-colour swatch-colour--tito-grey">

              </div>
              <span class="swatch-name">
                $tito-grey
              </span>
            </div>

            <div class="design-guide--swatch">
              <div class="swatch-colour swatch-colour--tito-purple">

              </div>
              <span class="swatch-name">
                $tito-purple
              </span>
            </div>
          </div>
        </div>

        <div class="design-guide--section">
          <h2 class="design-guide--section-heading">Text</h2>
          <span class="text-primary">Primary</span>
          <span class="text-secondary">Secondary</span>
          <span class="text-success">Success</span>
          <span class="text-warning">Warning</span>
          <span class="text-danger">Danger</span>
        </div>

        <div class="design-guide--section">
          <h2 class="design-guide--section-heading">Buttons</h2>

          <button type="button" class="btn btn-primary btn-add-modal">
            Add
          </button>
          <button type="button" class="btn btn-primary">
            Primary
          </button>
          <button type="button" class="btn btn-secondary">
            Secondary
          </button>
          <button type="button" class="btn btn-success">
            Success
          </button>

          <button type="button" class="btn btn-warning">
            Warning
          </button>

          <button type="button" class="btn btn-danger">
            Danger
          </button>

          <button type="button" class="btn btn-link">
            Link
          </button>
        </div>

        <div class="design-guide--section">
          <h2 class="design-guide--section-heading">Typography</h2>

          <h1 class="heading-1">Heading 1</h1>
          <h2>Heading 2</h2>
          <h3>Heading 3</h3>
          <h4>Heading 4</h4>
          <h5>Heading 5</h5>
          <h6>Heading 6</h6>
          <p>This is a paragraph</p>
        </div>

        <div class="design-guide--section">
          <h2 class="design-guide--section-heading">Badges</h2>

          <span class="badge badge-primary">Primary</span>
          <span class="badge badge-secondary">Secondary</span>
          <span class="badge badge-success">Success</span>
          <span class="badge badge-danger">Danger</span>
          <span class="badge badge-warning">Warning</span>
          <span class="badge badge-light">Light</span>
          <span class="badge badge-dark">Dark</span>
          <span class="badge badge-link">Link</span>

        </div>

        <div class="design-guide--section">
          <h2 class="design-guide--section-heading">Alerts</h2>

          <div class="alert alert-primary" role="alert">
            This is a primary alert—check it out!
          </div>
          <div class="alert alert-secondary" role="alert">
            This is a secondary alert—check it out!
          </div>
          <div class="alert alert-success" role="alert">
            This is a success alert—check it out!
          </div>
          <div class="alert alert-danger" role="alert">
            This is a danger alert—check it out!
          </div>
          <div class="alert alert-warning" role="alert">
            This is a warning alert—check it out!
          </div>
          <div class="alert alert-dark" role="alert">
            This is a dark alert—check it out!
          </div>

        </div>



        <div class="design-guide--section">
          <h2 class="design-guide--section-heading">Accessibility</h2>
          <p>Team Tito Ltd is committed to ensuring digital accessibility for all users, including those with disabilities. We are making continuous improvements to our user experience for all users and applying relevant accessibility standards as part of our process.</p>
          <h3 class="mb-2">Conformance Status</h3>
          <p>The Web Content Accessibility Guidelines (WCAG) is a set of standards that must be adhered to in order to ensure accessibility for people with disabilities. There are 3 defined levels of conformance: A, AA and AAA.</p>
          <p>Tito is partially conformant with <a class="link-primary" href="https://www.w3.org/TR/WCAG21/">WCAG WCAG 2.1 AA</a>. This means that there are some parts of the content that do not conform to the accessibility standard.</p>
          <h3 class="mb-2">Feedback</h3>
          <p>
            If you would like to provide feedback about the accessibility of Tito, please contact our Accessibility Advocate by phone, email or an alternative method provided.
          </p>
          <p>Murray Summers - support@tito.io</p>
          <h3 class="mb-2">Assessment Technique</h3>
          <p>Team Tito Ltd analyzed the accessibility of Tito with the following approach:</p>
          <p>The content was assessed by an internal employee or the developer of the content</p>
          <p>This accessibility statement was created on 2024-05-31 using the <a href="https://accessibleweb.com/accessibility-statement-generator/">Accessible Web Accessibility Policy Generator.</a></p>
          <h3 class="mb-2">Testing</h3>

          <p>How to test for accessibility issues in your browser:</p>
          <ol>
            <li>Download and install the <a class="link-primary" href="https://www.deque.com/axe/browser-extensions/
">Axe accessibility browser extension</a></li>
            <li>Go to the page you wish to test.</li>
            <li>Open web inspector and click on the Axe dev tools tab.</li>
            <li>Ensure you have the settings on "WCAG 2.1 AA" and "Best Practices: ON".</li>
            <li>Click on "Scan all of my page". You will see any accessibility issues along with guidance.</li>
          </ol>

          <p>How to add accessibility tests in the test suite:</p>

          <ol>
            <li>Open the relevant system test</li>
            <li>Add an <code>assert_accessible(page)</code> assertion to the place where you want to test. Remember that capybara is asynchronous so you need to ensure the page is fully loaded before adding your assertion. Typically the easiest way to do this is checking for content on the page: <code>assert page.has_content?("Some text on the page")</code>.</li>
          </ol>

          <pre>
            <code>
              test "create a new release" do
                visit("/funconf/funconf-iii/releases")
                assert_section_title("Tickets")

                assert_accessible(page)
              end
            </code>
          </pre>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Design Guide | Tito",
    };
  }
};
</script>

<style>
html,
body,
div#app {
  height: 100%;
}
</style>
