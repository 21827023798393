<template>
  <div :id="`searchable_select_container_${id}`">
    <select :id="id" :disabled="disabled">
      <option v-if="allow_empty" value="">{{ placeholder }}</option>
      <option v-for="option in options" :selected="value == option[option_value]" :value="option[option_value]">
        {{ option[option_label] }}
      </option>
    </select>
  </div>
</template>

<script>

  import Choices from 'choices.js';

  import "choices.js/public/assets/styles/choices.css";

  export default({

    props: {
      value: {

      },
      disabled: {
        type: Boolean,
        default: false
      },
      options: {
        type: Array,
        required: true
      },
      id: {
        type: String,
        required: true
      },
      placeholder: {
        type: String
      },
      option_label: {
        type: String,
        default: "label"
      },
      option_value: {
        type: String,
        default: "value"
      },
      allow_empty: {
        type: Boolean,
        default: true
      },
      clear_on_select: {
        type: Boolean,
        default: false
      }
    },

    emits: ['update:modelValue'],

    data(){
      return {
        choices: null
      }
    },

    mounted() {
      const selectElement = document.querySelector(`#${this.id}`);
      const choices = new Choices(selectElement, {
        removeItems: true,
        shouldSort: false,
        itemSelectText: ""
      });

      this.choices = choices;

      selectElement.addEventListener('change', (event) => {
        const selectedValue = event.target.value;
        this.$emit('update:modelValue', selectedValue);
        this.$emit('input', selectedValue);

        if (this.clear_on_select) {
          this.choices.setChoiceByValue("")
        }
      });
    },

    watch: {
      options(newOptions, oldOptions){
        // When the options are updated we need to set the selected value.
        this.choices.setChoices(newOptions, this.option_value, this.option_label, true);
        this.choices.setChoiceByValue(this.value)
      },
      value: function(newVal, oldVal) {
        this.choices.setChoiceByValue(newVal)
      }
    }
  })
</script>

<style>

  .choices {
    margin-bottom: 0px;
    text-align: left;
  }
  .choices__inner {
    background: #fff;
    border: 1px solid #ced4da !important;
    min-height: 0px;
    max-height: calc(1.5em + 2rem + 2px);
  }

  .choices__list--single {
    padding-top: 3px;
  }

  .choices__placeholder {
    opacity: 0.7 !important;
    color: #222;
  }

  .choices__list--dropdown{
    z-index: 3000 !important;
  }
</style>