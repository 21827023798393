
.tito-command-wrapper {
  align-items: center;
  justify-content: center;
  display: none;
  z-index: 1300;
  &.open {
    display: flex;
  }
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  input {
    background: inherit;
    width: 100%;
    height: 6rem;
    border: 0;
    border-bottom: 1px solid #eee;
    padding-left: 1.5rem;

    &:focus {
      outline: none;
    }
  }
  .tito-command {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.3);
    height: 50vh;
    position: relative;
    width: 50%;

    .tito-command-list {
      margin-bottom: 0;
      overflow: scroll;
      position: absolute;
      top: 6rem;
      left: 0;
      right: 0;
      bottom: 0;

      li {
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
        padding: 0rem;

        &.selected {
          background: #eee;
        }
        &.loading {
          padding: 1rem 1.5rem;
        }
        .quick-link {
          display: flex;
          align-items: center;
          padding: 1rem 1.5rem;
          width: 100%;
          &:hover {
            background: rgba(238, 238, 238, 0.25);
          }
          .title {
            flex: 1;
          }
          .url {
            color: #0478ff;
            font-size: 1.2rem;
            padding: 0.5rem;
          }
        }
      }
    }
  }
}
