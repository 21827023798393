
@import "../../../../assets/stylesheets/variables";

.UppyInput {
  margin-top: 1rem;

  ::v-deep .uppy-DragDrop-inner {
    margin: 1.5rem;
    padding: 0 !important;

    svg {
      margin-bottom: 0px;
      width: 4rem;
    }
  }

  ::v-deep .uppy-Root {
    padding: 2rem;
  }

  ::v-deep .uppy-DragDrop-label {
    font-size: 1.5rem !important;
    margin-bottom: 0 !important;
  }
}

.UppyInput--disabled {
  background-color: #e9ecef;
  border-radius: 0.25rem;  
  border: 1px solid #ced4da;
  color: #8f9092;
  padding: 1rem 1.5rem;
}

.uploaded {
  align-items: center;
  display: flex;
  margin-top: 1rem;
}

.filename {
  background-color: #f0f0f0;
  border-radius: 4px;
  color: black;
  display: block;
  padding: 0.5rem 1rem;
  position: relative;
  .fa-check-circle {
    color: $tito-green;
    position: absolute;
    top: -4px;
    right: -7px;
  }
}
