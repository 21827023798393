
@import "../../../../assets/stylesheets/variables";

.dropdown-header {
  margin: 0;
  padding: 0.5rem 1.3rem;
}
.group-status {
  border-bottom: 1px solid $divider;
  padding: 5px 0;
  b a {
    font-weight: 700;
  }
}
.time-frame-filter {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 0.25rem 1.5rem 0.25rem 3.5rem;

  label {
    flex: 1;
  }

  label {
    font-size: 1.25rem;
    font-weight: 700;
  }
}
