<template>
  <div>
    <div class="warning-banner" v-if="bannerEnabled">
      <p v-if="anonymisationRequested">
        This event is scheduled to be anonymized. For more information or to
        cancel, see
        <router-link :to="{ name: 'settingsAnonymise' }"
          >Settings &gt; Anonymize</router-link
        >.
      </p>
      <p v-else-if="anonymisationInProgress">
        This event is currently being anonymized.
      </p>
      <p v-else-if="anonymisationFinished">
        This event has been anonymized, it can only be used as a record of
        aggregate stats e.g. number of tickets registered, total income, etc.
        <router-link :to="{ name: 'settingsDelete' }"
          >Delete event</router-link
        >.
      </p>
      <p v-else-if="deletionRequested">
        This event is scheduled to be deleted. For more information or to
        cancel, see
        <router-link :to="{ name: 'settingsDelete' }"
          >Settings &gt; Delete</router-link
        >.
      </p>
      <p v-else-if="deletionInProgress">
        This event is currently being deleted.
      </p>
      <p v-else-if="deletionAwaitingRetentionPeriod">
        This event has been partially deleted. It will be fully deleted once the
        required retention period for order related data has expired.
      </p>
      <p v-else-if="stripeChargesDisabled">
        Warning! Your Stripe account is currently restricted. Check your
        <a
          href="https://dashboard.stripe.com/dashboard"
          rel="noopener"
          target="_blank"
          >Stripe dashboard</a
        >
        for more&nbsp;information.
      </p>
      <p v-else-if="liveAndPaymentOptionRequired">
        Warning! Your event is live with paid tickets but no payment option. Go
        to your
        <router-link :to="{ name: 'settingsMoney' }"
          >Money settings</router-link
        >
        to select a payment option.
      </p>
    </div>
    <div
      class="header--without-border"
      :class="{ 'header--with-banner': bannerEnabled }"
    >
      <div class="header--content">
        <div class="breadcrumbs">
          <router-link to="/" class="home"> <tito-logo /> </router-link>
        </div>
        <header-support />
        <header-personal />
      </div>
    </div>
  </div>
</template>

<script>
import currentEvent from "application/mixins/currentEvent";
import HeaderPersonal from "../header/Personal.vue";
import HeaderSupport from "../header/Support.vue";
import TitoLogo from "../shared/TitoLogo.vue";

export default {
  mixins: [currentEvent],
  components: { HeaderPersonal, HeaderSupport, TitoLogo },
  computed: {
    anonymisationRequested() {
      return this.currentEvent.anonymisation_state == "requested";
    },

    anonymisationInProgress() {
      return this.currentEvent.anonymisation_state == "in_progress";
    },

    anonymisationFinished() {
      return this.currentEvent.anonymisation_state == "finished";
    },

    deletionRequested() {
      return this.currentEvent.deletion_state == "requested";
    },

    deletionInProgress() {
      return this.currentEvent.deletion_state == "in_progress";
    },

    deletionAwaitingRetentionPeriod() {
      return this.currentEvent.deletion_state == "awaiting_retention_period";
    },
  },
};
</script>

<style>
.warning-banner {
  background: #f9d7da;
  border: 1px solid #f5c2c7;
  border-radius: 0.5rem;
  color: #842029;
  padding: 1rem 2rem;
  text-align: center;
  z-index: 1;
}

@media only screen and (min-width: 768px) {
  .warning-banner {
    align-items: center;
    display: flex;
    height: 40px;
    margin: 0;
    padding: 1rem;
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    justify-content: center;
    z-index: 20;
  }
}

.warning-banner p {
  margin: 0;
}
</style>
