/* -----------------
   ----- FONTS -----
   ----------------- */

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
   "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
   sans-serif;

/* ------------------
   ----- LAYOUT -----
   ------------------ */

$app-width: 1600px;
$header-height: 70px;
$subheader-height: 49px;
$app-customizer-edit-width: 500px;

/* -------------------
   ----- COLOURS -----
   ------------------- */

/*
  See https://tito.design/styles/colour/
*/

/* ----- Brand Colours ----- */

$primary-blue: #0079ff;
$green: #02cdae;
$orange: #ff9c00;
$aubergine: #3c2f41;
$dark-grey: #222;
$light-grey: #f5f5f5;

/* ----- Text Colours ----- */

/* $text-dark:                 #4b5762; */
$text-dark: #222;
$text-muted: rgba($text-dark, 0.7);
$tito-light-blue: #0067e1;

/* $bg-gray:                   #eceff4; */
/* $bg-dark-1:                 #232e36; */
/* $bg-dark-2:                 #1f282f; */
/* $bg-dark-3:                 #141a1f; */

/* $tito-dark-blue:            #3c4a56; */
$tito-mid-blue: #578dbd;
$tito-red: #e12328;
$tito-orange: #f6af58;
$tito-orange-light: #feeede;
$tito-green: #1a8754;
$tito-grey: #d8d8d8;
$tito-purple: #7357bc;

$crazy-orange: #ff9c00;
$crazy-pink: #ff5f74;

/* $text-darker:               #475763; */

/* $header-bg:                 transparent; */
/* $subheader-bg:              lighten($tito-light-blue, 44%); */
$subheader-bg: white;
/* $subheader-blue:            #3179c8; */
$bg: #eceff4;

/* $bg-card:                   #eceff4; */
$divider: #dce1eb;
/* $highlight:                 lighten($subheader-blue, 46%); */
$highlight-active: lighten($tito-light-blue, 43%);
$highlight-hover: lighten($tito-light-blue, 53%); /* #f7faff */
$bg-disabled: #f5f5f5;

/* $titoBrandBlue:             #0079FF; */
/* $titoBrandGrey:             #F5F5F5; */

// Colour transition

/* $text-dropdown:             #212529; */
/* $ticket-unassigned:         #999; */
$status-green: $tito-green;
/* $text-input:                #5f727f; */
/* $text-viewer-dropdown:      #575757; */

// Random transparencies

$black-05: rgba(0, 0, 0, 0.05);
$black-10: rgba(0, 0, 0, 0.1);
$black-12: rgba(0, 0, 0, 0.12);
$black-20: rgba(0, 0, 0, 0.2);
$black-24: rgba(0, 0, 0, 0.24);
$black-25: rgba(0, 0, 0, 0.25);
$black-50: rgba(0, 0, 0, 0.5);

$white-50: rgba(255, 255, 255, 0.5);
$white-70: rgba(255, 255, 255, 0.7);

/* $unknown-red-20:            rgba(220, 95, 95, 0.2); */

$text-dark-50: rgba(75, 87, 98, 0.5);
/* $text-dark-60:              rgba(75, 87, 98, 0.6); */

/* // Unknown colours */

/* $unknown-border:            #e1e2e2; */
/* $unknown-border-2:          #d9eaf3; */
/* $unknown-border-3:          #efe4e4; */
/* $unknown-border-4:          #d1d2d4; */
/* $unknown-border-5:          #e6e6e6; */
/* $unknown-border-6:          #cfd6db; */
/* $unknown-border-7:          #ccc; */
/* $unknown-border-8:          #ced4da; */
/* $unknown-border-9:          #dde2e6; */
/* $unknown-border-10:         #cfd1d5; */
/* $unknown-border-11:         #eceeef; */

/* $unknown-bg:                #f3f6fb; */
/* $unknown-bg-2:              rgba(0,0,0,0.03); */
/* $unknown-bg-3:              #eff0f2; */
/* $unknown-bg-4:              #f7f9fb; */
/* $unknown-bg-5:              #f8dfdf; */
/* $unknown-bg-6:              #f2f3f7; */
/* $unknown-bg-7:              #eaeced; */
/* $unknown-bg-8:              #f5f6f7; */
/* $unknown-bg-9:              #dfdfdf; */
/* $unknown-bg-10:             #edeff4; */
/* $unknown-bg-11:             #5389b6; */
/* $unknown-bg-12:             #e2e2e2; */

/* $unknown-text:              #9ea4aa; */
/* $unknown-text-2:            #cfcfcf; */
/* $unknown-text-3:            #89949b; */
/* $unknown-text-4:            #bbb; */
/* $unknown-text-5:            #b8c2ca; */
/* $unknown-text-6:            #bfbfbf; */

/* $unknown-green:             #00f500; */

/* $unknown-blue:              #337ab7; */

/* $unknown-red:               #DC5F5F; */
