
table {
  margin-bottom: 0.5rem;
  td,
  th {
    border-left: 1px solid #ced4da;
    padding: 0.5rem 1rem;
    &:first-child {
      border-left: 0;
    }
  }
  th {
    border-bottom: 1px solid #ced4da;
    font-weight: 400;
  }
  td {
    padding: 0.5rem 1rem;
    text-align: right;
    &.discount {
      border-left: 0;
      font-style: italic;
    }
    &.invalid {
      color: #f65858;
      text-align: left;
    }
  }
}
.footer {
  margin-bottom: 0;
  border-radius: 0;
}
