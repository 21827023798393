
@import "../../../../assets/stylesheets/variables";

.viewer--content {
  border-color: $tito-orange;
  gap: 1.5rem;
  margin-bottom: 0px;
}

.locked-release-icon {
  color: $tito-orange;
}
