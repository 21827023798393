
@import "../../../../assets/stylesheets/variables";

div {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
p {
  background-color: $tito-orange;
  border-radius: 0 0 4px 4px;
  color: $text-dark;
  font-size: 0.8em;
  line-height: 1.2em;
  padding: 0 0.5rem;
}
