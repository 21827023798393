<template>
  <div>
    <h3 class="h5">Choose tickets</h3>
    <div class="form-group">
      <searchable-select
        v-if="!loading"
        id="release_search"
        placeholder="Type to search"
        option_label="title"
        option_value="release_id"
        :clear_on_select="true"
        @input="onSelect"
        :options="filteredSuggestions" 
      />
    </div>

    <div class="form-check">
      <label for="cross_event" class="form-check-label">
        <input
          type="checkbox"
          id="cross_event"
          class="form-check-input"
          v-model="crossEvent"
        />
        Include tickets from other events in search
      </label>
    </div>

    <div class="release-list">
      <div v-for="(item, index) in items">
        <div v-show="!item.hide" class="py-2 border-b">
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <label for="'bundle-'+ item.release_id">
                {{ item.title }}
                <span v-if="crossEvent"> ({{ item.event_title }}) </span>
              </label>
            </div>
            <div class="d-flex align-items-center">
              <input
                type="hidden"
                v-if="item.id"
                v-model="item.id"
                :name="
                  'release[super_combo_releases_attributes][' + index + '][id]'
                "
              />
              <input
                type="hidden"
                :name="
                  'release[super_combo_releases_attributes][' +
                  index +
                  '][combo_release_id]'
                "
                :value="item.release_id"
              />
              <input
                type="hidden"
                :name="
                  'release[super_combo_releases_attributes][' +
                  index +
                  '][_destroy]'
                "
                :value="item._destroy"
              />
              <button
                type="button"
                class="btn btn-link px-0"
                :class="{
                  disabled: item.quantity < 1,
                }"
                @click.prevent="decrement(index)"
              >
                <font-awesome-icon :icon="['fas', 'minus-circle']" />
              </button>
              <input
                type="text"
                :id="'bundle-' + item.release_id"
                class="form-control quantity-input mx-2"
                :name="
                  'release[super_combo_releases_attributes][' +
                  index +
                  '][quantity]'
                "
                v-model="item.quantity"
                @blur="blurQuantity(index)"
              />
              <button
                type="button"
                class="btn btn-link px-0"
                :class="{
                  disabled: item.quantity > 98,
                }"
                @click.prevent="increment(index)"
              >
                <font-awesome-icon :icon="['fas', 'plus-circle']" />
              </button>
              <button
                type="button"
                class="btn btn-link px-0 ml-2"
                @click.stop.prevent="removeItem(index)"
              >
                <font-awesome-icon :icon="['fal', 'trash']" fixed-width />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h3 class="h5">Options</h3>
    <form-field
      type="boolean"
      :model="release"
      field="enable_super_combo_summary"
      label="Enable bundle summary description"
    />

    <form-field
      type="boolean"
      :model="release"
      field="only_issue_combos"
      label="Only issue the bundled tickets, not this ticket"
    />
  </div>
</template>

<script>
import FormField from "../shared/FormField.vue";
import SearchableSelect from "application/components/shared/SearchableSelect.vue";
import Vue from "vue/dist/vue.esm";
import currentEvent from "application/mixins/currentEvent";

export default {
  mixins: [currentEvent],
  props: ["release"],
  components: { FormField, SearchableSelect },
  data() {
    return {
      items: [],
      suggestions: [],
      crossEvent: false,
      loading: true,
    };
  },
  mounted() {
    this.getSuggestions();
  },

  watch: {
    items: {
      handler(to) {
        // Any non-zero quantity should be destroyed
        to.forEach((item) => {
          if (item.quantity > 0) {
            item._destroy = false;
          } else {
            item._destroy = true;
          }
        });
      },
      deep: true,
    },
  },

  computed: {
    selectedReleaseIds() {
      return this.items.map((item) => item.release_id);
    },

    filteredSuggestions() {
      return this.suggestions
        .filter((release) => {
          if (this.crossEvent) {
            return release.release_id !== this.release.id;
          } else {
            return (
              release.release_id !== this.release.id &&
              release.event_id == this.currentEvent.id
            );
          }
        })
        .map((release) => {
          if (this.crossEvent) {
            return {
              release_id: release.release_id,
              title: `${release.title} (${release.event_title})`,
              release_title: release.title,
              event_title: release.event_title,
            };
          } else {
            return {
              release_id: release.release_id,
              title: release.title,
              release_title: release.title,
              event_title: release.event_title,
            };
          }
        });
    },
  },

  methods: {
    async getSuggestions() {
      this.loading = true;
      const result = await this.$http.get(
        `/my/super_combo_release_suggestions.json`
      );
      this.suggestions = result.data.releases;
      if (this.release.super_combo_releases) {
        this.items = this.release.super_combo_releases.map((scr) => {
          return {
            id: scr.id,
            release_id: scr.combo_release_id,
            event_title: this.eventTitleForRelease(scr.combo_release_id),
            title: scr.title,
            quantity: scr.quantity,
          };
        });
      }
      this.loading = false;
    },

    eventTitleForRelease(release_id) {
      const suggestion = this.suggestions.find(
        (r) => r.release_id == release_id
      );
      if (suggestion) {
        return suggestion.event_title;
      }
    },

    isSelected(option) {
      return this.selectedReleaseIds.includes(option.release_id);
    },

    onSelect(optionId) {
      if (optionId == ""){
        return;
      }

      let option = this.filteredSuggestions.find((i) => i.release_id == parseInt(optionId))

      if (this.isSelected(option)) {
        console.log("Already selected but adding", option);
        const r = this.items.find((i) => i.release_id == option.release_id);
        if (r._destroy) {
          r._destroy = false;
          r.hide = false;
          r.quantity = 1;
        }
        document.getElementById("bundle-" + option.release_id).focus();
      } else {
        console.log("Adding new", option);
        this.items.push({
          release_id: option.release_id,
          title: option.release_title,
          event_title: option.event_title,
          quantity: 1,
        });
      }
    },

    decrement(index) {
      let newQuantity = Math.max(0, this.items[index].quantity - 1);
      Vue.set(this.items[index], "quantity", newQuantity);
      document.getElementById("bundle-" + this.items[index].release_id).focus();
    },

    increment(index) {
      let newQuantity = Math.min(99, this.items[index].quantity + 1);
      Vue.set(this.items[index], "quantity", newQuantity);
      document.getElementById("bundle-" + this.items[index].release_id).focus();
    },

    blurQuantity(index) {
      if (!this.items[index].quantity || this.items[index].quantity < 0) {
        this.items[index].quantity = 0;
      } else if (this.items[index].quantity > 99) {
        this.items[index].quantity = 99;
      }
    },

    removeItem(index) {
      Vue.set(this.items[index], "hide", true);
      Vue.set(this.items[index], "quantity", "0");
    },
  },
};
</script>

<style scoped>
h3.h5 {
  margin-bottom: 1rem;
}
.border-t {
  border-top: 1px solid #eeeeee;
}
.border-b {
  border-bottom: 1px solid #eeeeee;
}
.release-list {
  margin: 1.5rem 0;
}
.release-list > div:first-child {
  border-top: 1px solid #eeeeee;
}
.release-list label {
  margin: 0;
}
</style>
