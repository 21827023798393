
@import "../../../../assets/stylesheets/variables";
span.locales-switcher {
  position: absolute;
  right: 0;
  top: -1.9rem;
  text-align: right;
  @media (min-width: 768px) {
  }
  a.locale_switch {
    border: 1px solid #d8d8d8;
    color: #fff;
    background: #d8d8d8;
    display: inline-block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 1.5rem;
    padding: 0 0.75rem;
    position: relative;
    top: -3px;
    text-transform: uppercase;
    z-index: 1;
  }
  &.is-invalid {
    a.locale_switch {
      border-color: $tito-red;
      border-bottom-color: #d8d8d8;
    }
    a.active {
      border-bottom-color: #fff;
    }
  }
  a.active {
    background: #fff;
    border-bottom-color: #fff;
    color: $tito-light-blue;
  }
}
