
.spreadsheet {
  background: #fff;
  font-size: 1rem;
  white-space: nowrap;

  td,
  th {
    border: 1px solid #ccc;
    padding: 2px 5px;
  }
}
