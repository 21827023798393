/*
xs: 480 > 0,
sm: 768 > 576px,
md: 992 > 768px,
lg: 1200 > 992px,
xl: 1600 > 1200px
*/

@mixin responsive-extra-small {
  @media (min-width: 0px) {
    @content;
  }
}

@mixin responsive-small {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin responsive-medium {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin responsive-large {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin responsive-larger {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin responsive-extra-large {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin responsive-super-large {
  @media (min-width: 1700px) {
    @content;
  }
}

@mixin h-responsive-medium {
  @media (max-height: 750px) and (min-width: 992px) {
    @content;
  }
}

@mixin responsive-tito {
  @media (max-width: 992px) and (min-width: 768px), (min-width: 1200px) {
    @content;
  }
}

@mixin momentum-scroll {
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}

@mixin asset($source) {
  background-image: url(#{$source});
  background-repeat: no-repeat;
  @media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
    background-image: url(#{$source});
  }
  @media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
    background-image: url(#{$source});
  }
}

@mixin overflow {
  text-indent: 150%;
  overflow: hidden;
  white-space: nowrap;
}

@mixin clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin asset($source) {
  background-image: url(image-path("#{$source}.png"));
  background-repeat: no-repeat;
  @media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
    background-image: url(image-path("#{$source}@2x.png"));
  }
  @media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
    background-image: url(image-path("#{$source}@3x.png"));
  }
}

@mixin linear-gradient($pos, $color1, $percent1, $color2, $percent2) {
  background-image: linear-gradient($pos, $color1 $percent1, $color2 $percent2);
  background-image: -o-linear-gradient($pos, $color1 $percent1, $color2 $percent2);
  background-image: -moz-linear-gradient($pos, $color1 $percent1, $color2 $percent2);
  background-image: -webkit-linear-gradient($pos, $color1 $percent1, $color2 $percent2);
  background-image: -ms-linear-gradient($pos, $color1 $percent1, $color2 $percent2);
}