<template>
  <div class="blank-state">
    <div class="index index--blank">
      <div class="card card--onboarding">
        <img :src="iconPath" alt="" /> <slot />
      </div>
    </div>
  </div>
</template>

<script>
import currentEvent from "application/mixins/currentEvent.js";
const icons = {
  attendees: require("images/flaticons/attendees.png"),
  activities: require("images/flaticons/activities.svg"),
  checkinLists: require("images/flaticons/checkin.svg"),
  discountCodes: require("images/flaticons/discount.svg"),
  interestedUsers: require("images/flaticons/interested-people.svg"),
  messages: require("images/flaticons/messages.svg"),
  optIns: require("images/flaticons/optins.svg"),
  questions: require("images/flaticons/questions.svg"),
  registrations: require("images/flaticons/receipt.svg"),
  releases: require("images/flaticons/ticket.svg"),
  rsvpLists: require("images/flaticons/rsvps.svg"),
  sources: require("images/flaticons/source.svg"),
  surveys: require("images/flaticons/surveys.svg"),
  termsets: require("images/flaticons/terms.svg"),
  upgrades: require("images/flaticons/upgrades.svg"),
  waitlistedPeople: require("images/flaticons/waiting-list.svg"),
};

export default {
  mixins: [currentEvent],
  props: {
    collection: null,
  },

  computed: {
    iconPath() {
      return icons[this.collection];
    },
  },
};
</script>

<style scoped>
.blank-state {
  flex: 1;
}
</style>
