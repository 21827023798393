
@import "../../../../assets/stylesheets/variables";

.attachment {
  border-bottom: 1px solid $divider;
  display: flex;
  align-items: center;

  a {
    color: $text-dark;
    padding: 1.5rem;
  }

  .attachment--action {
    font-size: 2rem;
    line-height: 1;
    margin: 1px 1rem 0 0;
    .fa {
      color: $tito-light-blue;
    }
  }

  .attachment--toggle {
    display: flex;
    flex: 1;
  }

  .attachment--name {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .btn-secondary {
    height: 3.5rem;
  }
  &.inactive {
    opacity: 0.5;
    .attachment--action {
      .fa {
        color: $text-dark;
      }
    }
  }
  &:hover {
    background-color: $highlight-hover;
  }
}
