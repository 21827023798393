
@import "../../../../assets/stylesheets/variables";
@import "../../../../assets/stylesheets/mixins";
.filtering {
  background: #fff;
  border: 1px solid $divider;
  max-height: 42vh;
  max-width: 25vw;
  min-width: 28rem;
  overflow: auto;
  position: absolute;
  top: 49px;
  right: -77px;
  width: 25vw;
  z-index: 1;
  @include responsive-small {
    right: 0;
  }
  a.dropdown-item {
    white-space: normal;
  }
}

.reset-apply {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid $divider;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  position: sticky;
  top: 0;
}
